/** @jsx jsx */
import { jsx } from '@emotion/core';

import Tag from '../Tag';

interface Props {
  tags: string[];
  vertical?: boolean;
}

const TagList = ({ tags, vertical = false }: Props) => {
  return (
    <div>
      {tags.map((tag, index) => (
        <span
          key={index.toString()}
          css={{
            marginRight: 10,
            display: vertical ? 'block' : 'inline-bl',
          }}
        >
          <Tag tag={tag} />
        </span>
      ))}
    </div>
  );
};

export default TagList;
