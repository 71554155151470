/** @jsx jsx */
import { jsx } from '@emotion/core';

interface Props {
  tag: string;
}

const Tag = ({ tag }: Props) => {
  return <span>🔖 {tag}</span>;
};

export default Tag;
