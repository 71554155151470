/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Link } from 'gatsby';

interface Props {
  siteTitle: string;
}

const Header = ({ siteTitle }: Props) => (
  <header
    style={{
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1250,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <div css={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `black`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
      </div>
      <nav>
        <Link
          to="/about"
          activeStyle={{ color: 'hotpink' }}
          style={{
            color: `black`,
            textDecoration: `none`,
            fontSize: 20,
            fontWeight: 'bold',
            marginRight: 10,
          }}
        >
          /About
        </Link>
        <Link
          to="/blog"
          activeStyle={{ color: 'hotpink' }}
          style={{
            color: `black`,
            fontSize: 20,
            fontWeight: 'bold',
            textDecoration: `none`,
            marginRight: 10,
          }}
        >
          /Blog
        </Link>
        <Link
          to="/tags"
          activeStyle={{ color: 'hotpink' }}
          style={{
            color: `black`,
            fontSize: 20,
            fontWeight: 'bold',
            textDecoration: `none`,
          }}
        >
          /Tags
        </Link>
      </nav>
    </div>
  </header>
);

export default Header;
