/** @jsx jsx */
import { jsx } from '@emotion/core';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import TagList from '../components/TagList';
import Utterances from '../components/Utterances';

const PostTemplate = ({
  data, // this prop will be injected by the GraphQL query below.
}) => {
  const {
    markdownRemark: {
      frontmatter: { title, date, tags },
      html,
    },
  } = data; // data.markdownRemark holds our post data

  return (
    <Layout>
      <div className="blog-post-container">
        <div className="blog-post">
          <h1>{title}</h1>
          <span>🗓{date}</span>
          <TagList tags={tags} />
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <Utterances />
      </div>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        tags
        title
      }
    }
  }
`;
